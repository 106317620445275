import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from "@auth0/auth0-react";

import AuthorizedApolloProvider from './AuthorizedApolloProvider';

// import ReactPWAInstallProvider, { useReactPWAInstall } from 'react-pwa-install';

import smartlookClient from 'smartlook-client';
import myLogo from "./logo.svg";

export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const AllApp = (props) => {
//   const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

//   const handleClick = () => {
//     pwaInstall({
//       title: "Install Web App",
//       logo: myLogo,
//       features: (
//         <ul>
//           <li>Cool feature 1</li>
//           <li>Cool feature 2</li>
//           <li>Even cooler feature</li>
//           <li>Works offline</li>
//         </ul>
//       ),
//       description: "This is a very good app that does a lot of useful stuff. ",
//     })
//       .then(() => alert("App installed successfully or instructions for install shown"))
//       .catch(() => alert("User opted out from installing"));
//   };

  return (
    <React.StrictMode>
      <Auth0Provider
        domain="internetofgardens.eu.auth0.com"
        clientId="16sLJnzguUr62u0Heiyfi33kJzq53GPR"
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience="hasura"
        scope="read:current_user update:current_user_metadata"
      >
        <AuthorizedApolloProvider>
          <App history={history}/>
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(
  <AllApp />,
  document.getElementById('root')
);

if (window.location.origin == 'https://bahcevan.internetofgardens.com'){
  smartlookClient.init('95e3459e4e56aecdb28bb73b7c53f56e476e36bb');
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
