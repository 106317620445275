import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { IonHeader, IonToolbar, IonGrid, IonRow, IonCol, IonContent, IonPage, IonModal, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonItem, IonLabel, IonSelect, IonPopover, IonTitle, IonItemDivider } from '@ionic/react';
import UploadModal from './UploadModal';
import GraphsModal from './GraphsModal';
import AllPhotosModal from './AllPhotosModal';
import AddOperationModal from './AddOperationModal';

import styles from './styles.scss';


const GET_GARDEN_DETAILS = gql`
  query GardenDetails($id: Int!) {
    gardens(where: {id: {_eq: $id}}) {
      id
      field_id
      name
      humidity
      temperature
      moisture
      luminosity
      last_photo_taken_at
      last_data_read_at
      last_photo_url
      updated_at
    }
  }
`;

const GET_GARDEN_OPERATIONS = gql`
  query GardenOperations($gardenId: Int!) {
    garden_operations(where: {garden_id: {_eq: $gardenId}}, order_by: {done_at: desc}) {
      id
      notes
      done_at
    }
  }
`;

function GardenDetails() {
  const [showModal, setShowModal] = useState(false);
  const [showModalGraphs, setShowModalGraphs] = useState(false);
  const [showModalAddOperation, setShowModalAddOperation] = useState(false);
  const pageRef = React.createRef();

  const { id } = useParams();

  const { loading: loading, error: error, data: data } = useQuery(GET_GARDEN_DETAILS, {
    variables: { id },
  });

  const { loading: operationsLoading, error: operationsError, data: operationsData, refetch: operationsRefetch } = useQuery(GET_GARDEN_OPERATIONS, {
    variables: { gardenId: id },
    pollInterval: 500,
  });

  if (loading || operationsLoading) return <p>Loading...</p>;
  if (error || operationsError) return <p>Error :(</p>;

  const garden = data.gardens[0];

  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar color={showModal ? 'medium' : ''}>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonTitle>{showModal ? garden.name : 'Bahçe Detayları'}</IonTitle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size="4"></IonCol>
            <IonCol size="4">
              <h1>{garden.name}</h1>
            </IonCol>
            <IonCol size="4">
              <IonModal isOpen={showModal} presentingElement={pageRef.current} swipeToClose={true} class="newTypeOfModal" onDidDismiss={() => setShowModal(false)}>
                <IonContent>
                  <AllPhotosModal fieldId={garden.field_id} gardenId={garden.id} />
                  <IonButton size="small" color="light" onClick={() => setShowModal(false)}>x</IonButton>
                </IonContent>
              </IonModal>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol><img width="320" height="180" src={`https://media.internetofgardens.com/320x180/${garden.last_photo_url}`} /></IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="8">
              <IonRow>
                <IonCol>
                  🌡 {garden.temperature}
                  ☀️ {garden.luminosity}
                  🌊 {garden.humidity}
                  💦 {garden.moisture}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonModal isOpen={showModalGraphs} presentingElement={pageRef.current} swipeToClose={true} cssClass='my-custom-class' onDidDismiss={() => setShowModalGraphs(false)}>
                    <IonContent>
                      <GraphsModal fieldId={garden.field_id} gardenId={garden.id} />
                      <IonButton onClick={() => setShowModalGraphs(false)}>Kapat</IonButton>
                    </IonContent>
                  </IonModal>
                  <IonButton size="small" color="light" class="buttonLink" onClick={() => setShowModalGraphs(true)}>Grafik detayları</IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="3">
              <IonButton size="small" color="light" class="buttonLink" onClick={() => setShowModal(true)}>Bütün fotoğraflar</IonButton>
            </IonCol>
          </IonRow>
          <IonItemDivider></IonItemDivider>
          <IonRow>
            <IonCol>Bahçe planları</IonCol>
          </IonRow>
          <IonItemDivider></IonItemDivider>
          <IonRow>
            <IonCol>
              <div>
                Timeline
                <br />
                <IonModal isOpen={showModalAddOperation} presentingElement={pageRef.current} swipeToClose={true} cssClass='my-custom-class' onDidDismiss={() => setShowModalAddOperation(false)} onDidDismiss={() => operationsRefetch()}>
                  <IonContent>
                    <AddOperationModal fieldId={garden.field_id} gardenId={garden.id} />
                    <IonButton onClick={() => setShowModalAddOperation(false)}>Kapat</IonButton>
                  </IonContent>
                </IonModal>
                <IonButton size="small" color="primary" onClick={() => setShowModalAddOperation(true)}>Biraz önce suladım</IonButton>
                <IonList>
                  {operationsData.garden_operations.map(gardenOperation => (
                    <IonItem key={gardenOperation.id}>
                      {gardenOperation.notes}
                    </IonItem>
                  ))}
                </IonList>
                <br /><small>En son çekilen fotağraf: {garden.last_photo_taken_at}</small>
                <br /><small>Güncelleme zamanı: {garden.last_data_read_at}</small>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default GardenDetails;
