import React from 'react';
import {
  LineChart, AreaChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine,
} from 'recharts';

function SensorsCharts({sensorsData}) {
  return (
    <React.Fragment>
      <React.Fragment>
        <h2>Işık</h2>
        <AreaChart
          width={350}
          height={300}
          data={sensorsData}
          margin={{
            top: 20, right: 50, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="datetime" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine x="Page C" stroke="red" label="Max" />
          <ReferenceLine y={9800} label="Max" stroke="red" />
          <Area type="monotone" dataKey="luminosity" stroke="#8884d8" />
        </AreaChart>
      </React.Fragment>
      <React.Fragment>
        <h2>Sıcaklık</h2>
        <AreaChart
          width={350}
          height={300}
          data={sensorsData}
          margin={{
            top: 20, right: 50, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="datetime" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine x="Page C" stroke="red" label="Max" />
          <ReferenceLine y={9800} label="Max" stroke="red" />
          <Area type="monotone" dataKey="temperature" stroke="#8884d8" />
        </AreaChart>
      </React.Fragment>
      <React.Fragment>
        <h2>Nem</h2>
        <AreaChart
          width={350}
          height={300}
          data={sensorsData}
          margin={{
            top: 20, right: 50, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="datetime" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine x="Page C" stroke="red" label="Max" />
          <ReferenceLine y={9800} label="Max" stroke="red" />
          <Area type="monotone" dataKey="humidity" stroke="#8884d8" />
        </AreaChart>
      </React.Fragment>
      <React.Fragment>
        <h2>Toprak Nemi</h2>
        <AreaChart
          width={350}
          height={300}
          data={sensorsData}
          margin={{
            top: 20, right: 50, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="datetime" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine x="Page C" stroke="red" label="Max" />
          <ReferenceLine y={9800} label="Max" stroke="red" />
          <Area type="monotone" dataKey="moisture" stroke="#8884d8" />
        </AreaChart>
      </React.Fragment>
    </React.Fragment>
  );
}

export default SensorsCharts;
