import React, { useState } from 'react';

import { IonHeader, IonToolbar,IonProgressBar, IonThumbnail, IonContent, IonPage, IonModal, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonListHeader, IonItem, IonLabel, IonSelect, IonPopover, IonTitle } from '@ionic/react';
import DropzoneS3Uploader from '../../../components/react-dropzone-s3-uploader'


class UploadDisplay extends React.Component {

  renderFileUpload = (uploadedFile, i) => {
    const {
      filename,   // s3 filename
      fileUrl,    // full s3 url of the file
      file,       // file descriptor from the upload
    } = uploadedFile

    // <img src={`https://media.internetofgardens.com/100x100/${filename}`} />

    return (
      <IonItem  key={i}>
        <IonThumbnail>
          <img width="100" height="100" src={`https://media.internetofgardens.com/100x100/${filename}`} />
        </IonThumbnail>
      </IonItem>
    )
  }

  render() {
    const {uploadedFiles, s3Url} = this.props
    return (
      <div>
        {uploadedFiles.map(this.renderFileUpload)}
      </div>
    )
  }
}

export default function UploadModal(props) {
  const [filesCount, setFilesCount] = useState(0);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);

  const c = React.createRef();

  const handleFinishedUpload = info => {
    // console.log('File uploaded with filename', info.filename)
    // console.log('Access it on s3 at', info.fileUrl)
    console.log('file uploaded')
    setUploadedFilesCount(uploadedFilesCount + 1);
  }

  const handleDrop = (files, rejectedFiles) => {
    setFilesCount(files.length);

    return true;
  }

  return (
    <React.Fragment>
      <IonList>
        {filesCount > 0 && (
          <React.Fragment>
            <IonListHeader>
              <IonLabel>Uploading {uploadedFilesCount}/{filesCount}</IonLabel>
            </IonListHeader>
            <IonProgressBar value={uploadedFilesCount/filesCount*100}></IonProgressBar>
          </React.Fragment>
        )}
        <DropzoneS3Uploader
          ref={c}
          onFinish={handleFinishedUpload}
          onDrop2={handleDrop}
          s3Url='https://my-bucket.s3.amazonaws.com'
          accept='image/*, video/*'
          maxSize={1024 * 1024 * 5}
          upload={{server: 'https://u9lne9c7nl.execute-api.eu-central-1.amazonaws.com/dev/getPresignedUploadUrl', signingUrl:"/",signingUrlMethod: "POST", signingUrlQueryParams: {uploadType: 'avatar', fieldId: props.fieldId, gardenId: props.gardenId}}}
        >
          <UploadDisplay />
        </DropzoneS3Uploader>
      </IonList>
      <IonButton onClick={() => c.current.open()}>Click here to upload photos/videos</IonButton>
    </React.Fragment>
  );
}
