import React, { useState, useEffect, useRef } from 'react';

import { IonHeader, IonToolbar,IonProgressBar, IonThumbnail, IonContent, IonPage, IonModal, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonListHeader, IonItem, IonLabel, IonSelect, IonPopover, IonTitle } from '@ionic/react';

import SensorsCharts from './SensorsCharts';

export default function GraphsModal(props) {
  const initialData = null;
  const [sensorsData, setSensorsData] = useState(initialData)

  const sensorsChartData = !sensorsData ? [] : sensorsData.chartData;

  const c = React.createRef();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    fetch('https://u9lne9c7nl.execute-api.eu-central-1.amazonaws.com/dev/sensorsdata?gardenId=' + props.gardenId).then(res => res.json()).then((data) => setSensorsData(data.data.attributes))
  }

  function doRefresh(event) {
    getData();
    event.detail.complete(); //todo: move above to a promise
  }

  return (
    <React.Fragment>
      <IonTitle>Grafikler</IonTitle>
      <React.Fragment>
        <SensorsCharts sensorsData={sensorsChartData} />
      </React.Fragment>
      <IonButton onClick={() => getData()}>Yenile</IonButton>
    </React.Fragment>
  );
}
