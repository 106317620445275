import React  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { calendar, location, informationCircle, people, leaf, school } from 'ionicons/icons';
import Home from '../../views/Home';
// import SchedulePage from './SchedulePage';
// import SpeakerList from './SpeakerList';
// import SpeakerDetail from './SpeakerDetail';
// import SessionDetail from './SessionDetail';
// import MapView from './MapView';
// import About from './About';


import About from '../../views/About';
import Devices from '../../views/Devices';
import Dashboard from '../../views/Dashboard';
import Gardens from '../../views/Gardens';
import GardenDetails from '../../views/Gardens/GardenDetails';


const MainTabs = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/gardens" />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}

        <Route path="/tabs/devices" render={() => <Devices />} exact={true} />
        <Route path="/tabs/gardens" render={() => <Gardens />} exact={true} />
        <Route path="/tabs/learn" render={() => <Home />} exact={true} />
        <Route path="/tabs/social" render={() => <Home />} exact={true} />
        <Route path="/tabs/gardens/:id" component={GardenDetails} />

        <Route path="/tabs/schedule" render={() => <Home />} exact={true} />
        <Route path="/tabs/speakers" render={() => <Home />} exact={true} />
        <Route path="/tabs/speakers/:id" component={Home} exact={true} />
        <Route path="/tabs/schedule/:id" component={Home} />
        <Route path="/tabs/speakers/sessions/:id" component={Home} />
        <Route path="/tabs/map" render={() => <Home />} exact={true} />
        <Route path="/tabs/about" render={() => <Home />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="schedule" href="/tabs/gardens">
          <IonIcon icon={leaf} />
          <IonLabel>Bahçeler</IonLabel>
        </IonTabButton>
        <IonTabButton disabled={true} tab="speakers" href="/tabs/learn">
          <IonIcon icon={school} />
          <IonLabel>Öğren</IonLabel>
        </IonTabButton>
        <IonTabButton disabled={true} tab="map" href="/tabs/social">
          <IonIcon icon={people} />
          <IonLabel>Sosyal</IonLabel>
        </IonTabButton>
        <IonTabButton tab="about" href="/tabs/devices">
          <IonIcon icon={informationCircle} />
          <IonLabel>Cihazlar</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
