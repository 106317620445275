import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import moment from 'moment';

import { IonRefresher, IonRefresherContent, IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonItem, IonLabel, IonSelect, IonPopover, IonTitle } from '@ionic/react';

function Devices() {
  const initialData = null;
  const [deviceData, setDeviceData] = useState(initialData)

  useEffect(() => {
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24*26);

    getData();
  }, []);

  function getData() {
    fetch('https://u9lne9c7nl.execute-api.eu-central-1.amazonaws.com/dev/devicedata').then(res => res.json()).then((data) => setDeviceData(data.data.attributes))
  }

  function doRefresh(event) {
    getData();
    event.detail.complete(); //todo: move above to a promise
  }

  const chartData = !deviceData ? [] : deviceData.chartData;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Cihazlar</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <h3>Son mesaj alınma zamanı: </h3>{(deviceData && deviceData.timestamps[0]) ? (
          <React.Fragment>
            <span>{moment(deviceData.timestamps[0]).fromNow()}   <br/><small>{moment(deviceData.timestamps[0]).format()}</small></span>
            <AreaChart width={350} height={300} data={chartData} margin={{ top: 60, right: 20, bottom: 5, left: 0 }}>
              <Area type="stepAfter" dataKey="value" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="timestamp" />
              <YAxis domain={[0, 2]} type="number" interval={1} />
              <Tooltip />
            </AreaChart>
            <br/>
          </React.Fragment>
        ) : (
          <span>No connection info</span>
        )}
      </IonContent>
    </IonPage>
  );

}

export default Devices;
