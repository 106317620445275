import React, { useState, useEffect, useRef } from 'react';

import { IonHeader, IonToolbar,IonProgressBar, IonThumbnail, IonContent, IonPage, IonModal, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonListHeader, IonItem, IonLabel, IonSelect, IonPopover, IonTitle, IonTextarea } from '@ionic/react';

import SensorsCharts from './SensorsCharts';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';

export default function AddOperationModal(props) {
  const initialData = null;
  const [sensorsData, setSensorsData] = useState(initialData)
  const [text, setText] = useState('Manuel sulama yapıldı');
  const sensorsChartData = !sensorsData ? [] : sensorsData.chartData;
  const [selectedDate, setSelectedDate] = useState((new Date()).toISOString());

  const c = React.createRef();

  const ADD_OPERATION = gql`
  mutation AddGardenOperation($notes: String!, $done_at: timestamptz!, $garden_id: Int!) {
    insert_garden_operations_one(object: {notes: $notes, done_at: $done_at, garden_id: $garden_id}) {
      id
      notes
      done_at
      garden_id
    }
  }
`;
  const [addOperation, { data }] = useMutation(ADD_OPERATION);
  useEffect(() => {
    addOperation({ variables: { notes: 'Manuel sulama yapıldı', done_at: moment().format(), garden_id: props.gardenId } });
  }, []);

  function getData() {
    // fetch('https://u9lne9c7nl.execute-api.eu-central-1.amazonaws.com/dev/sensorsdata?gardenId=' + props.gardenId).then(res => res.json()).then((data) => setSensorsData(data.data.attributes))
  }

  function doRefresh(event) {
    getData();
    event.detail.complete(); //todo: move above to a promise
  }

  return (
    <React.Fragment>
      <IonTitle color="success" size="large">Sulama işlemi kaydedildi</IonTitle>
      <IonTextarea value={text} onIonChange={e => setText(e.detail.value)}></IonTextarea>
      <IonDatetime displayFormat="D MMM YYYY H:mm" min="2019" value={selectedDate} onIonChange={e => setSelectedDate(e.detail.value)}></IonDatetime>
      <IonButton onClick={() => getData()} fill="clear" color="warning">Kaydet</IonButton>
      <IonButton onClick={() => getData()} color="danger">Sil</IonButton>
    </React.Fragment>
  );
}
