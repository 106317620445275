import React, { useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from '../../components/LogoutButton';

import { IonHeader, IonToolbar,IonProgressBar, IonThumbnail, IonContent, IonPage, IonModal, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonListHeader, IonItem, IonLabel, IonSelect, IonPopover, IonTitle } from '@ionic/react';

function Home() {
  const { user } = useAuth0();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Ana Sayfa</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <span>Hoşgeldin {user.name}!</span>
      </IonContent>
    </IonPage>
  );
}

export default Home;
