import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  Link
} from "react-router-dom";

import { IonHeader, IonToolbar,IonProgressBar, IonThumbnail, IonContent, IonPage, IonModal, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonListHeader, IonItem, IonLabel, IonSelect, IonPopover, IonTitle } from '@ionic/react';


const GET_GARDEN_PHOTOS = gql`
  query GardenPhotos($gardenId: Int!) {
    garden_photos(where: {garden_id: {_eq: $gardenId}}, limit:72, order_by: {taken_at: desc}) {
      url
      id
      taken_at
    }
  }
`;


export default function AllPhotosModal(props) {
  const { loading: loading, error: error, data: data } = useQuery(GET_GARDEN_PHOTOS, {
    variables: { gardenId: props.gardenId },
  });

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata :(</p>;

  return (
    <React.Fragment>
      <IonList>
            {data.garden_photos.map(gardenPhoto => (
              <IonItem  key={gardenPhoto.id}>
                <img width="320" height="180" src={`https://media.internetofgardens.com/320x180/${gardenPhoto.url}`} />
              </IonItem>
            ))}
      </IonList>
    </React.Fragment>
  );
}
