import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { calendarOutline, hammer, moonOutline, help, informationCircleOutline, logIn, logOut, mapOutline, peopleOutline, person, personAdd, people, leafOutline, schoolOutline } from 'ionicons/icons';

import { useAuth0 } from "@auth0/auth0-react";

// import { connect } from '../data/connect';
// import { setDarkMode } from '../data/user/user.actions';

import './style.scss'

const routes = {
  appPages: [
    { title: 'Bahçeler', path: '/tabs/gardens', icon: leafOutline },
    //{ title: 'Öğren', path: '/tabs/learn', icon: schoolOutline },
    //{ title: 'Sosyal', path: '/tabs/social', icon: peopleOutline },
    { title: 'Cihazlar', path: '/tabs/devices', icon: informationCircleOutline }
  ],
  loggedInPages: [
    //{ title: 'Hesap', path: '/account', icon: person },
    //{ title: 'Yardım', path: '/support', icon: help },
  ],
  loggedOutPages: [
    { title: 'Giriş', path: '/login', icon: logIn },
  //  { title: 'Yardım', path: '/support', icon: help },
    { title: 'Kayıt ol', path: '/signup', icon: personAdd }
  ]
};


const Menu = (props) => {
  const location = useLocation();
  const menuEnabled = true;
  const isAuthenticated = true;
  const darkMode = false;
  const { match, history } = props;
  const { logout } = useAuth0();


  function setDarkMode(darkMode){
    return false;
  }


  function renderlistItems(list) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>Bahcevan</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {isAuthenticated ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
          <IonItem detail={false} button onClick={() => { logout({ returnTo: window.location.origin }) }}>
            <IonIcon slot="start" icon={logOut} />
            <IonLabel>Çıkış</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
