import React from "react";

/* Ionic */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* /Ionic */

import './App.scss';
import {
  Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";

import About from './views/About';
import Devices from './views/Devices';
import Dashboard from './views/Dashboard';
import Home from './views/Home';
import Gardens from './views/Gardens';
import HomeOrAuth from './views/HomeOrAuth';

import Menu from './components/Menu';
import MainTabs from './views/MainTabs';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);


const App = (props) => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <IonApp>
      <IonReactRouter history={props.history}>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main" animation={false}>
            <React.Fragment>
              <Switch>
                <ProtectedRoute path="/tabs" component={MainTabs} />
                <ProtectedRoute exact path="/about" component={About} />
                <ProtectedRoute exact path="/devices" component={Devices} />
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute exact path="/gardens" component={Gardens} />
                <Route exact path="/" component={HomeOrAuth} />
              </Switch>
            </React.Fragment>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
