import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  Link
} from "react-router-dom";

import { IonHeader, IonToolbar, IonGrid, IonRow, IonCol, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonItem, IonLabel, IonSelect, IonPopover, IonTitle, IonRouterLink, IonImg, IonCard, IonCardContent, IonCardHeader } from '@ionic/react';

const QUERY = gql`
  {
    gardens {
      id
      name
      humidity
      temperature
      moisture
      luminosity
      last_photo_url
    }
  }
`;

function Gardens() {
  const { loading, error, data } = useQuery(QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const garden = data.gardens[0];

  // <Link to={`/tabs/gardens/${garden.id}`}>{garden.name}</Link>
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonTitle>Bahçelerim</IonTitle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data.gardens.map(garden => (
          <IonCard key={garden.id}>
            <IonCardHeader class="ion-text-center"><h1>{garden.name}</h1></IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <Link to={`/tabs/gardens/${garden.id}`}>
                      <IonImg width="320" height="180" src={`https://media.internetofgardens.com/320x180/${garden.last_photo_url}`} />
                    </Link>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        🌡 {garden.temperature}
                        ☀️ {garden.luminosity}
                        🌊 {garden.humidity}
                        💦 {garden.moisture}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        ))}
      </IonContent>
    </IonPage>
  );
}

export default Gardens;
